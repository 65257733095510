import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CustomarService.css";
import configImg from "../../../images/configuration.png";
import whatsImg from "../../../images/ws.png";
import logo from "../../../images/bet_365_logo.png";

const CustomarService = () => {
  const [customarService, setCustomarService] = useState([]);

  useEffect(() => {
    fetch("./CustomarService.JSON")
      .then((res) => res.json())
      .then((data) => setCustomarService(data));
  }, []);
  return (
    <div>
      <div className="main-section">
        <div className="content-section">
          <div className="">
            <div className="logo-section">
              <img src={logo} alt="" />
            </div>
            {/* <h3>সততাই আমাদের একমাএ পন্থা</h3> */}
            {/* <div className="paymet-option">
                        <img className='img-fluid payment-img' src={paymentImg} alt="" />
                        <div className="bank-logo">
                            <img className='city-Bank-Logo' src={cityBankLogo} alt="" />
                            <img className='dutch-logo' src={dutchBankLogo} alt="" />
                        </div>
                    </div> */}
          </div>
          <div className="navigation-bar">
            <ul>
              <li>
                <Link className="nav-menu" to="/home">
                  <i className="fa-solid fa-house"></i>
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/customarservice">
                  <i className="fa-duotone fa-question question-icon"></i>{" "}
                  SERVICE
                </Link>
              </li>

              <li>
                <Link className="nav-menu" to="/admin">
                  <img className="configImg" src={configImg} alt="" /> ADMIN
                </Link>{" "}
                {/*<i className="fa-solid fa-user"></i>// */}
              </li>
              {/* <li>
                <Link className="nav-menu" to="/SubAdmin">
                  <img className="configImg" src={configImg} alt="" />
                  SUB ADMIN
                </Link>
              </li> */}
              <li>
                <Link className="nav-menu" to="/superagent">
                  <img className="configImg" src={configImg} alt="" /> SUPER
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/onlineagent">
                  <img className="configImg" src={configImg} alt="" />
                  MASTER
                </Link>
              </li>
              <li>
                <Link className="nav-menu" to="/OLDNEW">
                  <img className="configImg" src={configImg} alt="" />
                  OLD/NEW
                </Link>
              </li>
            </ul>
          </div>

          {/* marquee the paragarap    */}
          {/* <marquee className='marquee__list'>
                        <strong>Welcome To <a className='marquee__link' href="https://www.baaji365.live/#/"> Baaji365.Live </a>
                            Enjoy Beeting In Match Odds, Fancy & Live Casino
                        </strong>
                    </marquee> */}

          <div className="customar-service-list">
            <h4>BET365 CUSTOMER SERVICE LIST (TIME 10AM TO 10PM)</h4>
          </div>
          <div className="header_line_content">
            <p>
              কাস্টমার সার্ভিসে হোয়াটসাপ ব্যাতিত অন্য কোন এপ এর মাধ্যমে যোগাযোগ
              যাবে না এবং কাউকে আপনার একাউন্ট এর পাসোয়ার্ড দিবেন না{" "}
            </p>
          </div>
          <div className="customar-service-tabel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">TYPE</th>
                  <th scope="col">NAME</th>
                  <th scope="col">WHATSAPP LINK</th>
                  <th scope="col">PHONE NUMBER</th>
                </tr>
              </thead>
              <tbody>
                {customarService.map((customar) => (
                  <>
                    <tr key={customar.id}>
                      <td>{customar.type}</td>
                      <td>{customar.name}</td>
                      <td>
                        <div className="socail-icon">
                          <a href={customar.whatsapp}>
                            <img
                              id="agent_link"
                              src={whatsImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a>
                        </div>
                      </td>
                      <td>{customar.number}</td>
                    </tr>
                    {/* <tr className="customarAgent">
                      <td colSpan={4}>
                        <p className="tabel-details">
                          কাস্টমার সার্ভিসে হোয়াটসঅ্যাপ ব্যাতিত অন্য কোন এপ এর
                          মাধ্যমে যোগাযোগ যাবে না এবং কাউকে আপনার একাউন্ট এর
                          পাসোয়ার্ড দিবেন না ।
                        </p>
                      </td>
                    </tr> */}
                  </>
                ))}
              </tbody>
            </table>
          </div>

          <div className="tabel-description">
            <p>
              কাষ্টমার সার্ভিস এর কাউকেই আপনার পাসওয়ার্ড দিবেন না। শুধু আপনার
              ফায়ার এর ইউজার নেম দিবেন - যদি প্রয়োজন হয়।
            </p>
            {/* <ul>
              <li>
                *** স্বাধারন তথ্য জানার জন্য "General Inquiry" এর সাথে যোগাযোগ
                করার জন্য অনুরোধ করা হল।
              </li>
              <li>
                *** বেট বিষয়ক তথ্য জানার জন্য "General Inquiry" এর সাথে যোগাযোগ
                করার জন্য অনুরোধ করা হল।
              </li>
              <li>
                *** পয়েন্ট স্বম্পর্কিত কোন অভিযোগ এর জন্য "CUSTOMER SERVICE"
                সাথে যোগাযোগ করার জন্য অনুরোধ করা হল।
              </li>
              <li>
                *** বেট স্বম্পর্কিত কোন অভিযোগ এর জন্য "CUSTOMER SERVICE" সাথে
                যোগাযোগ করার জন্য অনুরোধ করা হল।
              </li>
              <li>
                *** এজেন্ট স্বম্পর্কিত কোন অভিযোগ এর জন্য "CS MANAGER" সাথে
                যোগাযোগ করার জন্য অনুরোধ করা হল।
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomarService;
